html, textarea {
  font-family: 'Montserrat';
  -webkit-text-size-adjust: none;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root {
  height: 100%;
  background-color: #fafafa;
}

* {    
  box-sizing: border-box;   
}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  opacity: 0.1;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.5);
  -webkit-border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.ReactModal__Content {
  opacity: 0;
}

.ReactModal__Content--after-open {
  opacity: 1;
  transition: opacity 150ms;
}

.ReactModal__Content--before-close {
  opacity: 0;
}

svg {
  fill: rgba(77, 77, 77, 0.85);
  height: 24px;
  width: 24px;
}

svg .primary {
  fill: rgba(77, 77, 77, 0.85);
}

svg .secondary {
  fill: black;
}

input[type="datetime-local"], input[type="date"], input[type="time"], input[type="file"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  min-height: 40px;
}

input, textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.center {
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.debug {
  border: 1px solid red;
}

@-webkit-keyframes animation {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

@keyframes animation {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.loader {
  position: fixed;
  top: 64px;
  left: 0px;
  right: 0px;
  z-index: 1501;
  border-top: 2px solid #1b1b3c;
  -webkit-animation: animation 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
          animation: animation 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.ui.input {
  position: relative;
}

.ui[class*="left icon"].input > input {
  padding-left: 42px;
  padding-right: 16px;
}

.ui.icon.input > .icon {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  top: 0px;
}

.ui.icon.input > .icon > svg {
  fill: rgba(77, 77, 77, 0.85);
}

