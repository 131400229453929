.ui.input {
  position: relative;
}

.ui[class*="left icon"].input > input {
  padding-left: 42px;
  padding-right: 16px;
}

.ui.icon.input > .icon {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  top: 0px;
}

.ui.icon.input > .icon > svg {
  fill: rgba(77, 77, 77, 0.85);
}
